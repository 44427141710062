import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { 
    path: 'home', 
    loadChildren: () => import('./_pages/home/home.module').then(m => m.HomePageModule) 
  },
  { 
    path: 'list', 
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule) 
  },
  { 
    path: 'about', 
    loadChildren: () => import('./_pages/about/about.module').then(m => m.AboutPageModule) 
  },
  { 
    path: 'services', 
    loadChildren: () => import('./_pages/services/services.module').then(m => m.ServicesPageModule) 
  },
  { 
    path: 'projects', 
    loadChildren: () => import('./_pages/projects/projects.module').then(m => m.ProjectsPageModule) 
  },
  { 
    path: 'register', 
    loadChildren: () => import('./_pages/register/register.module').then(m => m.RegisterPageModule) 
  },
  { 
    path: 'contact', 
    loadChildren: () => import('./_pages/contact/contact.module').then(m => m.ContactPageModule)
   }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
