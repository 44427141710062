import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'About',
      url: '/about',
      icon: 'information-circle-outline'
    },
    {
      title: 'Services',
      url: '/services',
      icon: 'list-outline'
    },
    {
      title: 'Projects',
      url: '/projects',
      icon: 'file-tray-full-outline'
    },
    {
      title: 'Register',
      url: '/register',
      icon: 'journal'
    },
    {
      title: 'Contact',
      url: '/contact',
      icon: 'pin'
    }
  ];

  currentPage = "/home";

  constructor(
    private platform: Platform,
    private router:Router
  ) {
    this.initializeApp();

    router.events.subscribe((_: NavigationEnd) =>{
      // console.log("current url", this.router.url);
      this.currentPage = this.router.url;
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {});
  }
}
